import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useEffect, useState } from "react";
import logo from "../assets/img/leonlogo.jpg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";



function NavBar() {
    const [scrolled, setScrolled] = useState(false);
    const [activeLink, setActiveLink] = useState("home");
    const onScroll = () => {
        if(window.scrollY > 100){
            setScrolled(true);
        }else{
            setScrolled(false);
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);
    const onUpdateActiveLink = (link) => {
        setActiveLink(link);
    }
  return (
    <Navbar  expand="lg" className={`${scrolled ? "scrolled" : ""}`}>
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt="Logo"
            src={logo}
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className={`${activeLink ?'active navbar-link':'navbar-link'}`} onClick={()=>onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#skills"  className={`${activeLink ?'active navbar-link':'navbar-link'}`} onClick={()=>onUpdateActiveLink('skills')}>Skills</Nav.Link>
            <Nav.Link href="#project"  className={`${activeLink ?'active navbar-link':'navbar-link'}`} onClick={()=>onUpdateActiveLink('projects')}>Projects</Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="#home">
                <img src={navIcon1} alt="Logo" />
              </a>
              <a href="#skills">
                <img src={navIcon2} alt="Logo" />
              </a>
              <a href="#project">
                <img src={navIcon3} alt="Logo" />
              </a>
            </div>
            <button className="btn btn-outline-success" type="button" onClick={()=>console.log('connect')}>
             <span>Let's connect</span> 
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
