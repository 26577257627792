import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import { Col, Row } from 'react-bootstrap'
import {ArrowRightCircle} from 'react-bootstrap-icons'
import HeaderImg from '../assets/img/header-img.svg'
import { useEffect, useState } from 'react'

const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [typingText, setTypingText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const rotateText = [ "Frontend Developer", "Backend Developer", "Full Stack Developer" ];
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta);
        return () => clearInterval(ticker);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[typingText]);

      const tick = () => {
        let i = loopNum % rotateText.length;
        let fullText = rotateText[i];
        let updateText = isDeleting ? fullText.substring(0, typingText.length - 1) : fullText.substring(0, typingText.length + 1);
        setTypingText(updateText);
        if(isDeleting){
            setDelta(prevDelta => prevDelta / 2);
        }
        if(!isDeleting && updateText === fullText){
            setDelta(period);
            setIsDeleting(true);
        }else if(isDeleting && updateText === ''){
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(200);
        }

      };  
  return (
   <section className="banner" id="home">
    <Container>
        <Row className="align-items-center">
            <Col xs={12} xl={7} md={6}>
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>{`Hi I'm Leonti`}<br></br><span className='wrap'>{typingText}</span></h1>
                <p>I am a web developer with a passion for creating beautiful, responsive websites.</p>
                <button onClick={()=>console.log('connected')}>Let's connect <ArrowRightCircle size={25}/> </button>
            </Col>
            <Col xs={12} xl={5} md={6}>
                <img src={HeaderImg} alt="HeaderImg" />
            </Col>
            </Row>
    </Container>
    </section>
  )
}

export default Banner
