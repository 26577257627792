import React from "react";
import projImg1 from "../assets/img/onlinestore1.png";
import projImg2 from "../assets/img/apointments.png";
import projImg3 from "../assets/img/eventbook.png";
import projX from "../assets/img/project-img1.png"
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import projImg4 from "../assets/img/olivo.png";

const Projects = () => {
  const projectsFront = [
    {
      title: "Project 1",
      description: "Online Store",
      image: projImg1,
      url: "https://www.leoshonftp.com/public/tienda-online/",
    },
    {
      title: "Project 2",
      description: "MedacDent",
      image: projImg2,
      url: "https://www.leoshonftp.com/public/MedacDent/",
    },
    {
      title: "Project 3",
      description: "EventBook",
      image: projImg3,
      url: "https://didacticfire.web.app/",
    },
    {
      title: "Project 4",
      description: "Olivas Olivares",
      image: projImg4,
      url: "https://oliva-app.web.app",
    },
  ];
  const projectsBack = [
    {
      title: "Project 1",
      description: "Coming soon...",
      image: projX,
      url: "#",
    },
    {
      title: "Project 2",
      description: "Coming soon...",
      image: projX,
      url: "#",
    },
    {
      title: "Project 3",
      description: "Coming soon...",
      image: projX,
      url: "#",
    },
  ];
  const projectsFull = [
    {
      title: "Project 1",
      description: "Coming soon...",
      image: projX,
      url: "#",
    },
    {
      title: "Project 2",
      description: "Coming soon...",
      image: projX,
      url: "#",
    },
    {
      title: "Project 3",
      description: "Coming soon...",
      image: projX,
      url: "#",
    },
  ];
  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col>
            <h2>Projects</h2>
            <p>Here are some of my projects</p>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first" id="projects-tabs-tab-first">Front</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" id="projects-tabs-tab-second">Back</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third" id="projects-tabs-tab-third">Full</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projectsFront.map((project, index) => (
                      <ProjectCard
                        key={index}
                        title={project.title}
                        description={project.description}
                        image={project.image}
                        url={project.url}
                      />
                    ))}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row>
                    {projectsBack.map((project, index) => (
                      <ProjectCard
                        key={index}
                        title={project.title}
                        description={project.description}
                        image={project.image}
                        url={project.url}
                      />
                    ))}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row>
                    {projectsFull.map((project, index) => (
                      <ProjectCard
                        key={index}
                        title={project.title}
                        description={project.description}
                        image={project.image}
                        url={project.url}
                      />
                    ))}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="colorSharp2"
      />
    </section>
  );
};

export default Projects;
