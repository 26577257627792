import React from "react";
import { Col } from "react-bootstrap";

const ProjectCard = ({ title, description, image, url }) => {
  return (
    <Col sm={6} md={4} lg={4} xl={4}>
      <div className="proj-imgbx">
        <img src={image} alt={title} />
        <div className="project-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
          <a href={url} className="btn btn-primary">Go to Project</a>
      </div>
    </Col>
  );
};

export default ProjectCard;
