
import './App.css';
import Banner from './components/Banner';
import Navbar from './components/Navbar';
import Skills from './components/Skills';
import Projects from './components/Projects';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
     <Navbar/>
     <Banner/>
      <Skills/>
      <Projects/>
    </div>
  );
}

export default App;
